import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RootState } from "modules";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import parser from "html-react-parser";
import { useTranslation } from "react-i18next";

import * as S from "./Header.Styled";

import { setNewDateFunc, stringFormat } from "utils/commons";

import { ReactComponent as LogoMetagymLand } from "assets/logo/logo_metagym_land.svg";
import { ReactComponent as LogoMetagymSymbol } from "assets/logo/logo_metagym_symbol.svg";
import { ReactComponent as MenuIcon } from "assets/svg/ic_menu_lined.svg";
import SideNav from "components/navigation/sideNav/SideNav";

import { postApiPrivate } from "apis/api";
import { onLoginSuccess } from "apis/loginApi";
import { set_alert_modal, set_dorman_model } from "modules/common";
import {
  set_token,
  set_studio_programs,
  set_category_list,
  set_company_idx,
  set_company_name,
} from "modules/local";
import { cAPIMessagePack } from "utils/cAPI";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const companyName = store.local.companyName;

  const headerInfo = store.local.headerInfo;
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;
  const [time, setTime] = useState("00:00");
  const [isNav, setIsNav] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  const programidx = searchParams.get("programidx");
  const connectidx = searchParams.get("connectidx");
  const epidx = Number(searchParams.get("epidx"));

  //시계
  useEffect(() => {
    const timeInterval = setInterval(() => {
      const time = setNewDateFunc(new Date());
      const hhmm = `${time.hours
        .toString()
        .padStart(2, "0")}<span className="timeanimation">:</span>${time.minutes
        .toString()
        .padStart(2, "0")}`;
      setTime(hhmm);
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  const requestConnectApi = () => {
    const pathname = "user/connect";
    const param = {
      connectidx: Number(connectidx),
      programidx: Number(programidx),
      epidx: epidx,
    };

    postApiPrivate(pathname, param, (response: any) => {
      if (response?.result === "success") {
        const authenticatetoken = response?.authenticatetoken;
        const userinfo = response?.userinfo;
        const accessToken = authenticatetoken.jwt;
        const responseData = {
          data: {
            authenticatetoken: {
              jwt: accessToken,
              usrIdx: userinfo.usridx,
              // auto: isAuto,
            },
            programidx: programidx,
          },
        };

        onLoginSuccess(responseData, null);
        dispatch(set_token(accessToken));
        dispatch(set_studio_programs(response?.libraryGroup));
        dispatch(set_category_list(response?.categorylist));
        dispatch(set_company_idx(response?.companyIdx));
        dispatch(set_company_name(response?.companyName));

        if (response?.companyIdx !== 0) {
          cAPIMessagePack(
            `exbodyKiosk:?companyidx=${response?.companyIdx}&epidx=${epidx}`
          );
        }
        if (epidx !== 0) {
          navigate("/studio");
        } else {
          navigate("/");
        }
      } else if (response?.result === "failed") {
        if (response?.errtype === "no exist email") {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Err_Email_Pop_1"),
          };
          dispatch(set_alert_modal(alertModal));
        } else if (response?.errtype === "incorrect pw") {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Err_Password_Pop_1"),
          };
          dispatch(set_alert_modal(alertModal));
        } else if (response?.errtype === "dormant account") {
          const modalData = {
            onoff: true,
            last: response.logindate,
            dorman: response.dormantdate,
            usridx: response.usridx,
          };
          dispatch(set_dorman_model(modalData));
        } else {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Retry_Pop"),
          };
          dispatch(set_alert_modal(alertModal));
        }
      } else {
        console.log(pathname, response?.errmsg);
      }
    });
  };

  //user 체크
  useEffect(() => {
    if (pathname !== "/userCheck") {
      if (programidx && connectidx) {
        requestConnectApi();
      }
    } else {
      if (!usridx || usridx < 1) {
        navigate("/login");
      }
    }
  }, [pathname, usridx, programidx, connectidx]);

  return (
    <S.Header>
      {pathname !== "/login" &&
      pathname !== "/terms" &&
      pathname !== "/join" &&
      pathname !== "/pwInquiry" &&
      pathname !== "/pwInquiry/re" &&
      pathname !== "/idInquiry" &&
      pathname !== "/idInquiry/check" &&
      pathname !== "/userCheck" ? (
        <S.Logo logoSize={"34px"}>
          <LogoMetagymSymbol onClick={() => navigate("/")} />
        </S.Logo>
      ) : (
        <S.Logo logoSize={"20px"}>
          <LogoMetagymLand onClick={() => navigate("/login")} />
        </S.Logo>
      )}
      {pathname !== "/login" &&
        pathname !== "/terms" &&
        pathname !== "/join" &&
        pathname !== "/pwInquiry" &&
        pathname !== "/pwInquiry/re" &&
        pathname !== "/idInquiry" &&
        pathname !== "/idInquiry/check" &&
        pathname !== "/userCheck" && (
          <>
            <S.Time>{parser(time)}</S.Time>
            <S.User onClick={() => setIsNav(!isNav)}>
              <>
                {stringFormat(
                  t("MainPage_Nickname"),
                  companyName || headerInfo?.usrnick || headerInfo?.usrname
                )}
                <MenuIcon />
              </>
            </S.User>
          </>
        )}

      <SideNav open={isNav} onClickClose={() => setIsNav(false)} />
    </S.Header>
  );
};

export default Header;
